// +----------------------------------------------------------------------
// | quickadmin框架 [ quickadmin框架 ]
// +----------------------------------------------------------------------
// | 版权所有 2020~2022 南京新思汇网络科技有限公司
// +----------------------------------------------------------------------
// | 官方网站: https://www.quickadmin.icu
// +----------------------------------------------------------------------
// | Author: mj <931982149@qq.com>
// +----------------------------------------------------------------------
// | Description: 接口合集，用于页面按钮权限、列表权限等接口校验,无权限的角色将会隐藏按钮
// +----------------------------------------------------------------------

export const api = {
    wallet: {
        goWithdraw: '/admin/wallet/goWithdraw',
        myInfo: '/admin/wallet/myInfo',
        getBankInfo: '/admin/bankInfo/myInfo',
        saveBankInfo: '/admin/bankInfo/add',
        add:'/admin/withdraw/add'
    },
    doctor: {
        index: '/admin/hospital.doctor/index',
        add: '/admin/hospital.doctor/add',
        find: '/admin/hospital.doctor/find',
        edit: '/admin/hospital.doctor/edit',
        delete: '/admin/hospital.doctor/delete',
        status: '/admin/hospital.doctor/status',
        getBindSpecialistCode: '/admin/hospital.doctor/getBindSpecialistCode',
        getBindCode: '/admin/hospital.doctor/getBindCode',
        getABindCode: '/admin/system.admin/getBindCode',
        getQrcode: '/admin/system.admin/getQrcode',
        getBindSpecialistCode: '/admin/hospital.doctor/getBindSpecialistCode',
        unbindSpecialist: '/admin/hospital.doctor/unbindSpecialist',
        unBindUser1: '/admin/system.admin/Unbinding',
    },
    menu: {
        adminIndex: '/admin/system.menu/adminIndex',
        index: '/admin/system.menu/index',
        add: '/admin/system.menu/add',
        find: '/admin/system.menu/find',
        edit: '/admin/system.menu/edit',
        delete: '/admin/system.menu/delete',
        status: '/admin/system.menu/status',
    },
    role: {
        authData: '/admin/system.role/authData',
        authGroup: '/admin/system.role/authGroup',
        index: '/admin/system.role/index',
        add: '/admin/system.role/add',
        find: '/admin/system.role/find',
        edit: '/admin/system.role/edit',
        delete: '/admin/system.role/delete',
        status: '/admin/system.role/status',
        selectList: '/admin/system.role/selectList',
    },
    demo: {
        index: '/admin/demo/index',
        add: '/admin/demo/add',
        find: '/admin/demo/find',
        edit: '/admin/demo/edit',
        delete: '/admin/demo/delete',
        export: '/admin/demo/export',
    },
    admin: {
        index: '/admin/system.admin/index',
        add: '/admin/system.admin/add',
        find: '/admin/system.admin/find',
        edit: '/admin/system.admin/edit',
        delete: '/admin/system.admin/delete',
        status: '/admin/system.admin/status',
    },
    file: {
        index: '/admin/system.files/index',
        delete: '/admin/system.files/delete',
    },
    online: {
        index: '/admin/onlinecurd.index/index',
        getTables: '/admin/onlinecurd.index/getTables',
        getMainTableRow: '/admin/onlinecurd.index/getMainTableRow',
        getSubTableRow: '/admin/onlinecurd.index/getSubTableRow',
        save: '/admin/onlinecurd.index/save',
        status: '/admin/onlinecurd.index/status',
    },
    upload: {
        upload: '/admin/ajax/upload',
    },
    config: {
        index: '/admin/system.config/index',
        getConfig: '/admin/ajax/getConfig',
    },
    log: {
        index: '/admin/system.log/index',
    },
    route: {
        initIndex: '/admin/ajax/initIndex',
    },
    login: {
        index: '/admin/passport/index',
        register: '/admin/passport/register',
        userinfo: '/admin/passport/userinfo',
        logout: '/admin/passport/logout',
        update: '/admin/passport/update',
        getCaptcha: '/admin/ajax/getCaptcha',
        sendsms: '/admin/ajax/sendsms',
    },
    activity: {
        index: `/admin/cms.activity/index`,
        find: `/admin/cms.activity/find`,
        add: `/admin/cms.activity/add`,
        edit: `/admin/cms.activity/edit`,
        delete: `/admin/cms.activity/delete`,
        export: `/admin/cms.activity/export`,
        status: `/admin/cms.activity/status`,
        msgList: `/admin/cms.activity/msgList`,
    },
    profile: {
        index: `/admin/profile/myInfo`,
        edit: `admin/profile/edit`,
        apply: `/index/apply`,
    },
    banner: {
        index: `/admin/cms.banner/index`,
        find: `/admin/cms.banner/find`,
        add: `/admin/cms.banner/add`,
        edit: `/admin/cms.banner/edit`,
        delete: `/admin/cms.banner/delete`,
        export: `/admin/cms.banner/export`,
        status: `/admin/cms.banner/status`
    },
    advConfig:{
        index: `/admin/adv.advConfig/list`,
        find: `/admin/adv.advConfig/find`,
        add: `/admin/adv.advConfig/add`,
        edit: `/admin/adv.advConfig/edit`,
        delete: `/admin/adv.advConfig/delete`,
        export: `/admin/adv.advConfig/export`,
        status: `/admin/adv.advConfig/status`,
        images_list: `/admin/adv.advertisement/check_applet_images`,
    },
    advertisement:{
        add: `/admin/adv.advertisement/create_order`,
        pay:'/admin/adv.wechatPay/wechat_pay',
        supplement_order:'/admin/adv.advertisement/supplement_order',
        index:'/admin/adv.advertisement/order_list',
        find:'/admin/adv.advertisement/order_info',
        edit:'/admin/adv.advertisement/create_order',
        check_order_status:'/admin/adv.advertisement/check_order_status'
    },
    special:{
        sq_specialist_find:'/admin/hospital.sq_specialist/find',
        log:'/admin/hospital.specialistInviteLog/index'
    },
    guide:{
        index: `/admin/questionnaire.guide/list`,
        find: `/admin/questionnaire.guide/find`,
        add: `/admin/questionnaire.guide/add`,
        edit: `/admin/questionnaire.guide/edit`,
        delete: `/admin/questionnaire.guide/delete`
    },
}
