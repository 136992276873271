<template>
    <div>
        <el-select
            :disabled="disabled"
            :multiple="multiple"
            v-model="select_value"
            filterable
            remote
            placeholder="请选择"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="handleChange"
            clearable
        >
            <el-option v-for="item in selectPageOptions" :key="item[show_id]" :label="item.admin_id ? item.nickname : item[show_field]" :value="item[show_id]"> </el-option>
            <div class="selectPage">
                <el-link class="mr10" :underline="false" :disabled="page === 1" @click="moreOriginPage('preview')">上一页</el-link>
                <el-link class="mr10" :underline="false" :disabled="!has_next" @click="moreOriginPage('next')">下一页</el-link>
            </div>
        </el-select>
    </div>
</template>

<script>
export default {
    name: 'SelectPage',
    props: {
        url: String,
        officeId: Number,
        show_field: {
            type: String,
            default: 'name',
        },
        show_id: {
            type: String,
            default: 'id',
        },
        query_field: {
            type: String,
            default: 'name',
        },
        value: [Number, String, Array],
        disabled: Boolean,
        multiple: {
            type: Boolean,
            default: false,
        },
        //其他检索条件 {aa:{value:'',op:''}}
        filter: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            select_value: '',
            page: 1,
            limit: 10,
            has_next: true,
            loading: false,
            selectPageOptions: [],
        }
    },
    watch: {
        filter: {
            handler(val) {
                if (val) {
                    this.remoteMethod(this.officeId)
                }
            },
            immediate: true,
            deep: true,
        },

        value(newVal) {
            if (this.multiple) {
                this.select_value = Array.isArray(newVal) ? newVal : [newVal]
            } else {
                this.select_value = newVal
            }
        },
        officeId(){
        	this.select_value=""
        	this.remoteMethod(this.officeId)
        }
    },
    created() {
        this.remoteMethod(this.officeId)
    },
    destroyed() {},
    methods: {
        // 远程分页取值
        remoteMethod(office_id, step, query_value) {
            this.office_id = office_id
            this.loading = true
            this.selectPageOptions=[]
            this.request
                .get(this.url, {
                    params: {
                        page: this.page,
                        limit: this.limit,
                        show_id: this.show_id,
                        show_field: this.show_field,
                        query_field: query_value ? 'id' : this.query_field,
                        office_id: office_id,
                        ...(query_value ? { query_value } : {}), // 编辑的时候传
                        ...this.formatQueryParams(this.filter), // 其他检索条件
                    },
                })
                .then(response => {
                    this.loading = false
                    if (response.data.length>0) {
                        this.has_next = true
                        this.selectPageOptions = response.data
                    } else if (step === 'next') {
                        this.has_next = false
                        this.page--
                    }
                })
        },

        // 上下页操作
        moreOriginPage(step) {
            if (step === 'preview') {
                this.page <= 0 ? (this.page = 1) : --this.page
                this.remoteMethod(this.office_id)
            } else {
                ++this.page
                this.remoteMethod(this.office_id, step)
            }
        },

        handleChange(val) {
            //返回 v-model的参数（关键）
            this.$emit('input', val)
        },
    },
}
</script>
